
import Button from '../../components/Button'
import ExternalLinks from './ExternalLinks'
import { getCart, saveCart } from '../../utils/localstorage';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isProb3 } from '../../utils/siteOrigin';
import CartProduct from './CartProduct';

export default function CheckOut() {
  const [cartProducts, setCartProducts] = useState(getCart() || []);
  const navigate = useNavigate();
  const prevPage = isProb3() ? "/bands" : "/become-a-member";

  const total = useMemo(() => {
    return cartProducts.reduce((total, p) => {
      return total + p.product.Price * p.qty
    }, 0);
  }, [cartProducts])
  const removeItem = (sku) => {
    setCartProducts(prev => prev.filter(p => p.product.Sku !== sku))
  }

  useEffect(() => {
    saveCart(cartProducts)
  }, [cartProducts])

  const emptyCart = () => {
    setCartProducts([])
  }
  const navigateToShipping = () => {
    if (!total) {
      toast.error("You must put a product in your cart!");
      return
    } else {
      navigate('/shipping')
    }
  }

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div className="grid grid-cols-5 lg:gap-10 sm:gap-5 gap-2">
        <div className="lg:col-span-3 col-span-5">
          <h1 className='md:text-6xl pt-2 text-4xl sm:text-5xl'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>SHOPPING CART</span>
          </h1>
          <div className='flex flex-col gap-3'>
            <div className='flex flex-row justify-between items-end mt-4 md:mt-7'>
              <h1 className="text-xl md:text-2xl font-light">ITEMS: </h1>
              {
                cartProducts.length > 0 &&
                <button class=" justify-center bg-[#00adef] px-3 py-2 text-white shadow-sm ml-2 sm:ml-3 sm:w-auto lg:text-xl text-sm rounded-full font-light" onClick={emptyCart}>Remove Items</button>
              }
            </div>
            {cartProducts.map(p => (
              <CartProduct key={p.product.Sku} product={p.product} qty={p.qty} />
            ))}
            {
              cartProducts.length > 0 &&
              <div class="flex justify-end"><button class="w-full justify-center bg-[#00adef] px-3 py-2 text-white shadow-sm sm:ml-3 sm:w-auto lg:text-xl text-sm rounded-full font-light" onClick={emptyCart}>Remove Items</button></div>
            }
            <div className='w-full border-0 border-t md:border-t-2 border-black my-2 flex justify-end text-xl md:text-2xl pt-1.5 md:pt-2'>
              ${total.toFixed(2)}
            </div>
            <div className='lg:block hidden'>
              <ExternalLinks />
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 col-span-5 border sm:h-[600px] lg:mt-8 mt-2 px-6 py-6 lg:text-xl text-lg flex flex-col justify-center font-normal">
          <div className='w-full flex justify-center'>
            <div className="flex flex-col items-center">
              <img alt="paypal credit" src="./assets/images/paypal.png" className="md:w-9/12" />
            </div>
          </div>
          <div className='flex flex-col items-center'>
            <div className='flex justify-between items-center sm:flex-row flex-col my-3 md:my-8 gap-2 sm:gap-3 self-stretch sm:self-auto'>
              <NavLink key='b3 bands' to={prevPage} className={"w-full sm:w-auto"}>
                <Button className="lg:text-xl text-sm sm:text-base md:text-lg px-4 py-3 md:px-6 md:py-4 rounded-full font-light sm:w-auto w-full !ml-0">
                  {'< Back'}
                </Button>
              </NavLink>
              <Button className="lg:text-xl text-sm sm:text-base md:text-lg px-4 py-3 md:px-6 md:py-4 rounded-full font-light sm:w-auto w-full !ml-0" onClick={navigateToShipping}>
                {'Next >'}
              </Button>
            </div>
            <p className='text-center text-sm md:text-base'>*ALL ORDERS COME WITH A 30 DAY MONEY BACK GUARANTEE*</p>
            <img alt="money-guarantee" src="./assets/images/guarantee.jpg" className="w-[112px] md:w-[150px] mt-1 md:mt-2" />
          </div>
        </div>
      </div>
      <div className='lg:hidden block'>
        <ExternalLinks />
      </div>
      {/* <div className='w-full flex justify-between mt-10 sm:flex-row flex-col'>
        <NavLink key='b3 bands' to="/become-a-member">
          <Button className="lg:text-2xl text-lg px-6 py-4 rounded-full font-light sm:w-auto w-full">
            {'< Back'}
          </Button>
        </NavLink>
      </div> */}
    </section>
  )
}