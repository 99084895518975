import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import AddToCart from "./AddToCart";
import B3Alert from "./B3Alert";
import B3Meal from "./B3Meal";
import B3Start from "./B3Start";
import B3StartCaffeineFree from "./B3StartCaffeineFree";
import Subscribe from "./Subscribe";

const B3StartImages = ['22.png', '25.png']
const B3StartCaffeineFreeImages = ['caffeine-free-1.png', 'caffeine-free-2.png']
const B3MealImages = ['14.png', '18.png']
const B3AlertImages = ['27.png', '29.png']

export default function NutritionProducts() {

  const b3StartSubscribePrice = 69.99
  const b3MealSubscribe = 59.99
  const b3AlertSubscribe = 54.99

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div>
        <img alt="money-guarantee" src="./assets/images/guarantee.jpg" className="absolute md:w-[100px] w-[60px] top-6 right-5 md:top-2 md:right-2" />
      </div>
      <h1 className='md:text-6xl pt-2 text-4xl sm:text-5xl'>
        <span>B</span>
        <span className='text-[#00adef] mr-3'>3</span>
        <span>FIT NUTRITION PRODUCTS</span>
      </h1>
      <div className="flex flex-col gap-16">
        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>START</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
            <div className="flex flex-col">
              <B3Start />
              <AddToCart sku='RCStart' />
              <Subscribe price={b3StartSubscribePrice} sku='Start1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3StartImages} allowExpand/>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>START CAFFEINE FREE</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
            <div className="flex flex-col">
              <B3StartCaffeineFree />
              <AddToCart sku='RCStartFR' />
              <Subscribe price={b3StartSubscribePrice} sku='StartFR1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3StartCaffeineFreeImages} allowExpand/>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>MEAL</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-x-6">
            <div className="flex flex-col">
              <B3Meal />
              <AddToCart sku='RCMeal' />
              <Subscribe price={b3MealSubscribe} sku='Meal1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3MealImages} allowExpand/>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h1 className='md:text-3xl pt-2 text-2xl mt-3 font-bold'>
            <span>B</span>
            <span className='text-[#00adef] mr-3'>3</span>
            <span>ALERT</span>
          </h1>
          <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-x-6">
            <div className="flex flex-col">
              <B3Alert />
              <AddToCart sku='RCAlert' />
              <Subscribe price={b3AlertSubscribe} sku='Alert1' />
            </div>
            <div className="flex lg:pt-5 pt-0">
              <Carousel swiperImages={B3AlertImages} allowExpand/>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full flex justify-between mt-10 sm:flex-row flex-col'>
        <NavLink key='b3 bands' to="/bands">
          <Button className="lg:text-2xl text-lg px-6 py-4 rounded-full font-light sm:w-auto w-full">
            {'< Back'}
          </Button>
        </NavLink>
        <NavLink key='nutrition products' to="/become-a-member">
          <Button className="lg:text-2xl text-lg px-6 py-4 rounded-full font-light sm:w-auto w-full sm:mt-0 mt-2">
            {'Next >'}
          </Button>
        </NavLink>
      </div>

    </section>
  )
}