import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import { getCart, saveCart } from '../../utils/localstorage';
import PRODUCTS from '../../../public/assets/products.json';

export default function AddToCart({price, sku}) {
  const notify = () => toast("Product is added!");
  const [qty, setQty] = useState(1)
  const product = PRODUCTS.find(p => p.Sku === sku);

  const handleAddToCart = (e) => {
    if (qty > 0) {
      notify();
      const cart = getCart()
      const existing = cart.find(p => p.product.Sku === sku)
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + qty
      } else {
        cart.push({ product, qty })
      }
      saveCart(cart)
    }
  }

  return (
    <div className="flex mt-8 sm:flex-row flex-col">
      <div className='flex items-center font-medium mr-4'>
        <span className='text-2xl sm:mr-0 mr-4'>${product.Price}</span>
        <Button
          onClick={handleAddToCart}
          className="text-lg px-8 py-3 rounded-full font-light"
          data-mdb-ripple="true" data-mdb-ripple-color="light">
          ADD TO CART
        </Button>
      </div>
      <div className='flex items-center sm:mt-0 mt-4'>
        <span className='text-2xl mr-2'>QTY : </span>
        <input type="number" name="qty" id="qty" defaultValue={1} min={0} value={qty} onChange={(e) => setQty(e.target.value)} className="block w-full flex-1 rounded border md:border-gray-400 border-[#00adef] px-2 py-1.5 w-[65px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="Qty" />
      </div>
    </div>
  )
}