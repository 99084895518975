import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { scrollIntoView } from "seamless-scroll-polyfill";
import AddToCartForm from "../../components/AddToCartForm";
import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import Bands from "./Bands";
import { isProb3 } from "../../utils/siteOrigin";

const SwiperImages = [
  'plank.png', 'person4.jpg', 'person1.jpg', 'person5.jpg',
]

export default function Page() {
  const nextPage = isProb3() ? "/check-out" : "/nutrition-products";
  const nextButtonRef = useRef(null);
  const scrollToElement = () => {
    if (nextButtonRef.current) {
      scrollIntoView(nextButtonRef.current,{
        behavior: 'smooth',
        block: 'start', 
        inline: 'nearest'
      });
    }
  };
  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div>
      </div>
      <h1 className='md:text-6xl pt-2 text-4xl sm:text-5xl'>
        <span>B</span>
        <span className='text-[#00adef] mr-3'>3</span>
        <span>BANDS</span>
      </h1>
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1">
        <div className="flex flex-col mt-5">
          <Bands />
          <div className="">
            <AddToCartForm onClose={scrollToElement}/>
          </div>
        </div>
        <div className="flex items-center">
          <Carousel swiperImages={SwiperImages} />
        </div>
      </div>
      <div className="flex text-center lg:mt-8 mt-4 md:justify-center relative items-center flex-col sm:flex-row justify-between pb-5">
        <div className="flex items-center md:absolute md:left-0 relative mr-2 py-4">
          <div className="flex flex-col ">
            <img alt="paypal credit" src="./assets/images/paypal-credit.png" className="w-[100px] md:w-[120px]" />
            <span className="text-[10px] font-semibold">$0 DOWN 0% INTEREST FOR 6 MONTHS</span>
          </div>
          <img alt="money-guarantee" src="./assets/images/guarantee.jpg" className="w-[70px] md:w-[90px] top-6 right-5 md:top-2 ml-2 md:right-2" />
        </div>
        <NavLink key='nutrition-products' to={nextPage} ref={nextButtonRef}>
          <Button className="lg:text-2xl text-lg px-6 py-4 rounded-full font-light">
            {'Next >'}
          </Button>
        </NavLink>
      </div>
    </section>
  )
}