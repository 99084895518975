import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Pagination, EffectCube, Keyboard } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cube";

export default function Carousel(props) {
  const { swiperImages } = props
  return (
    <Swiper
      navigation={true}
      modules={[Navigation, Pagination, EffectCube, Keyboard]}
      pagination={{
        clickable: true,
      }}
      grabCursor={true}
      className={`h-auto w-full lg:h-[350px] border mb-3 lg:mb-0 lg:w-full ${props.className ?? ""}`}
      loop={true}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      effect={"cube"}
      keyboard={{
        enabled: true,
      }}
    >
      {swiperImages.map(img => (
        <SwiperSlide key={img}>
          <div className="flex justify-center h-full">
            <img alt={img} src={`./assets/images/${img}`} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
