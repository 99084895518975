import { useState } from "react"
import { NavLink } from "react-router-dom"
import { toast } from 'react-toastify';
import { Disclosure, Tab } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import Button from "../../components/Button"
import Personal from "./Personal"
import { getCart, saveCart } from '../../utils/localstorage';
// import Professional from "./Professional";
// import ShareAndEarn from "./ShareAndEarn";

const categories = ['MEMBER BENEFITS'];

export default function Member() {
  const notify = () => toast("Product is added!");
  const memberProduct = {
    Price: 49.99,
    Sku: 'MemKit',
    ProductName: 'Member Kit',
    Image: 'memkit.png'
  }
  const fiveBooks = {
    Sku: 'B3Book5',
    ProductName: '5 Books',
    Price: 29.99,
    Image: 'books.png'
  }

  const thirtyBooks = {
    Sku: 'B3Book30',
    ProductName: '30 Books',
    Price: 164.99,
    Image: 'books.png'
  }
  const ninetyBooks = {
    Sku: 'B3Book90',
    ProductName: '90 Books',
    Price: 449.99,
    Image: 'books.png'
  }
  const cart = getCart()
  const [page, setPage] = useState('detail')
  const [showBook, setShowBook] = useState(cart.find(p => p.product.Sku === memberProduct.Sku));
  const [prefix, setPrefix] = useState('');
  const [available, setAvailable] = useState('');

  const addMemKit = () => {
    setPage('website');
    const existing = cart.find(p => p.product.Sku === memberProduct.Sku)
    setShowBook(true);
    if (existing) {
      return
    } else {
      cart.push({ product: memberProduct, qty: 1 })
      notify()
    }
    saveCart(cart)
  }

  const handleAddToCart = (product) => {
    const existing = cart.find(p => p.product.Sku === product.Sku)
    if (existing) {
      return
    } else {
      cart.push({ product: product, qty: 1 })
      notify()
    }
    saveCart(cart)
  }

  const checkAvailability = () => {
    fetch(`${process.env.REACT_APP_ORDER_API_URI}/api/rep/replicatedwebsite/${prefix}`)
      .then(res => res.json())
      .then(res => {
        if (res.replicatedNameOnWebSite) {
          setAvailable('Taken')
        } else {

          localStorage.setItem('memberWebsitePrefix', prefix)
          setAvailable('Available')
        }
      })
  }

  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <div>
        <img alt="money-guarantee" src="./assets/images/guarantee.jpg" className="absolute md:w-[100px] w-[60px] top-6 right-5 md:top-2 md:right-2" />
      </div>
      {page === 'detail' && <>
        <h1 className='md:text-6xl pt-2 text-5xl'>
          <span>B</span>
          <span className='text-[#00adef] mr-3'>3</span>
          <br className="sm:hidden block" />
          <span>MEMBERSHIP BENEFITS</span>
        </h1>
        <div className="flex flex-col gap-16">
          <div className="flex flex-col">
            <div className="flex pt-0 items-center justify-center">
              <img alt="member" src="./assets/images/Member-image.png" className="max-w-[250px] block lg:hidden" />
            </div>
            <h1 className='pt-2 text-xl mt-3'>
              <span>MEMBER BENEFITS FOR 1 YEAR INCLUDE</span>
            </h1>
            <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
              <div className="flex flex-col mt-2 mb-2">
                <div className="hidden md:block w-full px-2">
                  <Tab.Group>
                    <Tab.List className="flex pt-2">
                      {categories.map((category) => (
                        <Tab
                          key={category}
                          className={({ selected }) =>
                            clsx(
                              'py-2.5 xl:text-2xl font-light leading-5 mr-4 text-xl',
                              'focus:outline-none border-b-[2px] ',
                              selected
                                ? 'border-gray-800 mr-4'
                                : 'border-transparent'
                            )
                          }
                        >
                          {category}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                      <Tab.Panel key={0}>
                        <Personal />
                      </Tab.Panel>
                      {/* <Tab.Panel key={1}>
                        <Professional />
                      </Tab.Panel>
                      <Tab.Panel key={2}>
                        <ShareAndEarn />
                      </Tab.Panel> */}
                    </Tab.Panels>
                  </Tab.Group>
                </div>
                <div className="md:hidden mx-auto w-full rounded-2xl shadow bg-white p-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                          <span className='text-lg'>Member Benefits</span>
                          <ChevronUpIcon
                            className={`${open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-blue-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Personal />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  {/* <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                          <span className='text-lg'>Professional</span>
                          <ChevronUpIcon
                            className={`${open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-blue-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <Professional />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                          <span className='text-lg'>Share and Earn</span>
                          <ChevronUpIcon
                            className={`${open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-blue-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <ShareAndEarn />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> */}
                </div>
              </div>
              <div className="flex pt-0 items-center justify-center">
                <img alt="member" src="./assets/images/Member-image.png" className="max-w-[400px] hidden lg:block" />
              </div>
            </div>
            <div className="text-2xl ml-3 font-light">
              ${memberProduct.Price} *BILLED ANNUALLY*
            </div>
            <Button
              onClick={addMemKit}
              className="text-lg px-8 py-3 rounded-full font-light mt-2 lg:max-w-[200px]"
              data-mdb-ripple="true" data-mdb-ripple-color="light">
              ADD TO CART
            </Button>
          </div>
        </div>
        {/* {showBook && <>
          <h1 className='md:text-6xl text-5xl pt-10'>
            <span className='text-[#00adef] mr-3'>PURCHASE ADDITIONAL BOOKS</span>
          </h1>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col">
              <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-2">
                <div className="flex flex-col lg:mt-7 mt-5 gap-6 justify-center">
                  <NavLink key='for more info' target="_blank" to="https://b3sciences.com/what-the-b3-books/">
                    <span className="uppercase">TO LEARN MORE ABOUT THE 'WHAT THE <span className="text-black">B</span>3' BOOKLETS GO HERE (link)</span>
                  </NavLink>
                  <div className='flex items-center font-medium mr-4 sm:flex-row flex-col'>
                    <span className='text-2xl sm:mr-0 mr-4'>5 BOOKS: ${fiveBooks.Price}</span>
                    <Button
                      onClick={() => handleAddToCart(fiveBooks)}
                      className="text-lg px-8 py-3 rounded-full font-light"
                      data-mdb-ripple="true" data-mdb-ripple-color="light">
                      ADD TO CART
                    </Button>
                  </div>
                  <div className='flex items-center font-medium mr-4 sm:flex-row flex-col'>
                    <span className='text-2xl sm:mr-0 mr-4'>30 BOOKS: ${thirtyBooks.Price}</span>
                    <Button
                      onClick={() => handleAddToCart(thirtyBooks)}
                      className="text-lg px-8 py-3 rounded-full font-light"
                      data-mdb-ripple="true" data-mdb-ripple-color="light">
                      ADD TO CART
                    </Button>
                  </div>
                  <div className='flex items-center font-medium mr-4 sm:flex-row flex-col'>
                    <span className='text-2xl sm:mr-0 mr-4'>90 BOOKS: ${ninetyBooks.Price}</span>
                    <Button
                      onClick={() => handleAddToCart(ninetyBooks)}
                      className="text-lg px-8 py-3 rounded-full font-light"
                      data-mdb-ripple="true" data-mdb-ripple-color="light">
                      ADD TO CART
                    </Button>
                  </div>
                </div>
                <div className="flex lg:pt-5 pt-0 justify-center">
                  <NavLink key='for more info' to="https://vimeo.com/808075117">
                    <img alt="books" src="./assets/images/books.png" className="h-[300px]" />
                  </NavLink>
                </div>
              </div>

            </div>
          </div>
        </>} */}
        <div className='w-full flex justify-between mt-10 sm:flex-row flex-col'>
          <NavLink key='b3 bands' to="/nutrition-products">
            <Button className="lg:text-2xl text-lg px-6 py-4 rounded-full font-light sm:w-auto w-full">
              {'< Back'}
            </Button>
          </NavLink>
          <NavLink key='nutrition products' to="/check-out">
            <Button className="lg:text-2xl text-lg px-6 py-4 rounded-full font-light sm:w-auto w-full sm:mt-0 mt-2">
              {'Next >'}
            </Button>
          </NavLink>
        </div>
      </>}
      {page === 'website' && <>
        <h1 className='md:text-6xl pt-2 text-5xl'>
          <span className='text-[#00adef] mr-3'>Choose Your Personalized <span className="text-black">B</span>3 website</span>
        </h1>
        <div className="flex flex-col gap-16">
          <div className="flex flex-col">
            <div className="flex flex-col items-center gap-3 border mt-8 rounded-lg max-w-2xl border-gray-300 w-full mx-auto sm:px-8 px-5 py-5">
              <span className="text-xl font-bold">Example: bill<span className="font-light">.getonthebands.com</span></span>
              <div className='flex items-center gap-3'>
                <input name="email" value={prefix} onChange={(e) => setPrefix(e.target.value)} className="block flex-1 rounded border border-gray-400 px-2 py-1.5 w-[100px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="prefix" />
                <span className='text-2xl mr-2 font-light'>.b3.sciences.com</span>
              </div>
              {available && <div className={`text-md ${available === 'Available' ? 'text-green-500' : 'text-red-800'} `}>
                {available === 'Available' ? 'Congratulations, this will be your site name!' : 'Not available, please choose another name.'}
              </div>}
              <Button
                onClick={checkAvailability}
                className="text-lg px-8 py-3 rounded-full font-light mt-2 lg:max-w-[300px]"
                data-mdb-ripple="true" data-mdb-ripple-color="light">
                CHECK AVAILABILITY
              </Button>
            </div>
            <Button
              onClick={() => setPage('detail')}
              className="text-lg px-8 py-3 rounded-full font-light mt-4 lg:max-w-[200px]"
              data-mdb-ripple="true" data-mdb-ripple-color="light">
              Continue
            </Button>
          </div>
        </div>
      </>}

    </section>
  )
}