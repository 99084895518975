import { Fragment, useState } from 'react'
import { Listbox, Transition, Dialog } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify';
import Button from './Button'
import { getCart, saveCart } from '../utils/localstorage'

const productTypes = [
  {
    Sku: 'RCBandSt1',
    ProductName: 'Bands Set 1',
    category: '1 SET',
    Price: 449.99,
    qty: 1,
    Image: "b3_bands_set.jpg",
  },
  {
    Sku: 'RCBandSt2',
    ProductName: 'Bands Set 2',
    category: '2 SETS',
    Price: 819.99,
    save: 80,
    qty: 2,
    Image: "b3_bands_set.jpg",
  },
  {
    Sku: 'RCBandSt4',
    ProductName: 'Bands Set 4',
    category: '4 SETS',
    Price: 1469.99,
    save: 330,
    qty: 4,
    Image: "b3_bands_set.jpg",
  },
  {
    Sku: 'RCBandSt10',
    ProductName: 'Bands Set 10',
    category: '10 SETS',
    Price: 3329.99,
    save: 1170,
    qty: 10,
    Image: "b3_bands_set.jpg",
  },
]

const armBands = [
  {
    "Sku": "Bands1",
    "ProductName": "B3 Band Size 1",
    "Description": "B3 Band Size 1",
    "Image": "BandSets.png",
    "ImageSmall": "BandSets-sm.png",
    "Price": 0.00,
    "Weight": 0.00
  },
  {
    "Sku": "Bands2",
    "ProductName": "B3 Band Size 2",
    "Description": "B3 Band Size 2",
    "Image": "BandSets.png",
    "ImageSmall": "BandSets-sm.png",
    "Price": 0.00,
    "Weight": 0.00
  },
  {
    "Sku": "Bands3",
    "ProductName": "B3 Band Size 3",
    "Description": "B3 Band Size 3",
    "Image": "BandSets.png",
    "ImageSmall": "BandSets-sm.png",
    "Price": 0.00,
    "Weight": 0.00
  },
]

const legBands = [
  {
    "Sku": "Bands3",
    "ProductName": "B3 Band Size 3",
    "Description": "B3 Band Size 3",
    "Image": "BandSets.png",
    "ImageSmall": "BandSets-sm.png",
    "Price": 0.00,
    "Weight": 0.00
  },
  {
    "Sku": "Bands4",
    "ProductName": "B3 Band Size 4",
    "Description": "B3 Band Size 4",
    "Image": "BandSets.png",
    "ImageSmall": "BandSets-sm.png",
    "Price": 0.00,
    "Weight": 0.00
  },
  {
    "Sku": "Bands5",
    "ProductName": "B3 Band Size 5",
    "Description": "B3 Band Size 5",
    "Image": "BandSets.png",
    "ImageSmall": "BandSets-sm.png",
    "Price": 0.00,
    "Weight": 0.00
  }
]

const additionalSize5 = {
  "Sku": "RCSize5",
  "ProductName": "B3 Bands Pair Size 5",
  "Description": "",
  "Image": "BandSets.png",
  "ImageSmall": "BandSets-sm.png",
  "Price": 30,
  "Weight": 40
};



export default function AddToCartForm({ onClose }) {
  const notify = () => toast("Product is added!");
  const [selected, setSelected] = useState(productTypes[0])
  const [armSizes, setArmSizes] = useState([armBands[0]])
  const [legSizes, setLegSizes] = useState([legBands[0]])

  const [isOpen, setIsOpen] = useState(false)

  const handleSetChange = (newSet) => {
    setSelected(newSet)
    setArmSizes(Array(newSet.qty).fill(armBands[0]))
    setLegSizes(Array(newSet.qty).fill(legBands[0]))
  }

  const closeModal = () => {
    const cart = getCart()
    notify();
    // Add ProdctType - SET
    const existing = cart.find(p => p.product.Sku === selected.Sku)
    if (existing) {
      existing.qty = (Number(existing.qty) || 0) + 1
    } else {
      cart.push({ product: selected, qty: 1 })
    }
    onClose();

    // Add individual items
    armSizes.concat(legSizes).forEach(product => {
      const existing = cart.find(p => p.product.Sku === product.Sku)
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + 2
      } else {
        cart.push({ product, qty: 2 })
      }
      if (product.Sku === 'Bands5') {
        const existingAdditional = cart.find(p => p.product.Sku === 'RCSize5')
        if (existingAdditional) {
          existingAdditional.qty = (Number(existingAdditional.qty) || 0) + 1
        } else {
          cart.push({ product: additionalSize5, qty: 1 })
        }
      }
    })
    saveCart(cart)

    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  return (
    <div className="flex flex-col mt-5 md:mt-8">
      <div className='text-base md:text-xl capitalize'>How many sets do you want?</div>
      <div className="md:flex flex-row items-center gap-2 mt-1 md:mt-3">
        <Listbox value={selected} onChange={handleSetChange}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
              <span className="block truncate">{selected.category}: ${selected.Price}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400 sm:text-2xl"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                {productTypes.map((type, typeIdx) => (
                  <Listbox.Option
                    key={typeIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={type}
                  >
                    {({ selected }) => (
                      <div className='flex flex-col text-base font-light'>
                        <div>
                          <span
                            className={`block text-base truncate ${selected ? 'font-normal' : 'font-light'
                              }`}
                          >
                            {type.category}: ${type.Price}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon className="h-4 w-4 md:h-5 md:w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        {type.save && <div className='text-[#00adef] text-xs md:text-sm'>
                          *SAVE ${type.save}*
                        </div>}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        <Button
          type="button"
          onClick={openModal}
          className="text-lg px-8 py-3 rounded-full font-light mt-4 md:mt-0"
          data-mdb-ripple="true" data-mdb-ripple-color="light">
          ADD TO CART
        </Button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="lg:max-w-5xl max-w-3xl transform rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all sm:max-h-auto max-h-[700px] overflow-auto">
                  <Dialog.Title
                    as="h3"
                    className="text-xl md:text-2xl font-medium leading-6 text-[#00adef]"
                  >
                    Size Selection
                  </Dialog.Title>
                  <div>
                    <div className='flex lg:flex-row flex-col items-center justify-center'>
                      <div className='flex flex-col overflow-x-visible mb-4 md:p-4 md:min-h-[400px] justify-center'>
                        {Array.from(Array(selected.qty), (e, i) => {
                          return <div key={i} className="mt-4">
                            <div className='text-lg md:text-2xl capitalize text-[#00adef] mb-3'>Set #{i + 1}</div>
                            <div className='flex gap-2 flex-col sm:flex-row'>
                              <div className='flex flex-col'>
                                <div className='text-base md:text-xl capitalize mb-1 md:mb-2'>Arm Sizes</div>
                                <Listbox value={armSizes[i]} onChange={(value) => setArmSizes(prev => {
                                  const newValue = [...prev]
                                  newValue[i] = value
                                  return newValue
                                })}>
                                  <div className="relative mt-1">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                      <span className="block truncate">{armSizes[i].ProductName}</span>
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                          className="h-5 w-5 text-gray-400 sm:text-2xl"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Listbox.Button>
                                    <Transition
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                        {armBands.map((band, idx) => (
                                          <Listbox.Option
                                            key={band.Sku}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                              }`
                                            }
                                            value={band}
                                          >
                                            <div className='flex flex-col text-base font-light'>
                                              <div>
                                                <span
                                                  className={`block text-base truncate ${armSizes[i].Sku === band.Sku ? 'font-normal' : 'font-light'
                                                    }`}
                                                >
                                                  {band.ProductName}
                                                </span>
                                                {armSizes[i].Sku === band.Sku ? (
                                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon className="h-4 w-4 md:h-5 md:w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </div>
                                            </div>
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </Listbox>
                              </div>
                              <div className='flex flex-col sm:mt-0 mt-3'>
                                <div className='text-base md:text-xl capitalize mb-1 md:mb-2'>Leg Sizes</div>
                                <Listbox value={legSizes[i]} onChange={value => setLegSizes(prev => {
                                  const newValue = [...prev]
                                  newValue[i] = value
                                  return newValue
                                })}>
                                  <div className="relative mt-1">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                      <span className="block truncate">{legSizes[i].ProductName}</span>
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                          className="h-5 w-5 text-gray-400 sm:text-2xl"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Listbox.Button>
                                    <Transition
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                        {legBands.map((band) => (
                                          <Listbox.Option
                                            key={band.Sku}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                              }`
                                            }
                                            value={band}
                                          >
                                            <div className='flex flex-col text-base font-light'>
                                              <div>
                                                <span
                                                  className={`text-base block truncate ${legSizes[i].Sku === band.Sku ? 'font-normal' : 'font-light'
                                                    }`}
                                                >
                                                  {band.ProductName}
                                                </span>
                                                {legSizes[i].Sku === band.Sku ? (
                                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon className="h-4 w-4 md:h-5 md:w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </div>
                                              {band.Sku === 'Bands5' &&
                                                <div className='text-[#00adef] text-xs md:text-sm'>
                                                Additional $30
                                              </div>
                                              }
                                            </div>
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </Listbox>
                              </div>
                            </div>
                          </div>
                        })}
                      </div>
                      <div className='min-w-[300px] flex justify-center'>
                        <img alt="size chart" src="./assets/images/body-with-product.jpg" className=' h-[250px] md:h-[400px]' />
                      </div>
                    </div>
                    <div className='flex justify-center mt-3 w-full'>
                      <Button
                        type="button"
                        onClick={closeModal}
                        className="text-base md:text-lg px-8 py-3 rounded-full font-light mt-4 md:mt-0"
                        data-mdb-ripple="true" data-mdb-ripple-color="light">
                        ADD TO CART
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}