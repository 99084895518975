import clsx from 'clsx';
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, ShoppingCartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { isProb3 } from '../utils/siteOrigin';

const navigation = [
	{ name: 'B3 BANDS', path: '/bands', current: true },
	{ name: 'B3 NUTRITION PRODUCTS', path: '/nutrition-products', current: false },
	{ name: 'BECOME A MEMBER', path: '/become-a-member', current: false },
	{ name: 'CHECK OUT', path: '/check-out', current: false },
]

export default function Header() {
	const [searchParams] = useSearchParams()
	const [name, setName] = useState('');
	const [navs, setNavs] = useState(navigation);

	useEffect(() => {
		if (searchParams.get('sponsorId')) {
			if (localStorage.getItem('sponsorId') && (localStorage.getItem('sponsorId') !== searchParams.get('sponsorId'))){
				// clear cart and other local storage
				localStorage.removeItem('cart')
				localStorage.removeItem('billingAddress')
				localStorage.removeItem('shippingAddress')
				localStorage.removeItem('cardInfo')
				localStorage.removeItem('memberWebsitePassword')
			}
			localStorage.setItem('sponsorId', searchParams.get('sponsorId'))
		}
	}, [searchParams])
	useEffect(() => {
		const sponsorId = localStorage.getItem('sponsorId') || searchParams.get('sponsorId') || 22;
		fetch(`${process.env.REACT_APP_ORDER_API_URI}/api/Rep/sponsorId/${sponsorId}`)
			.then(res => res.json())
			.then(res => {
				if (res) {
					setName(`${res.firstName || ''} ${res.lastName || ''}`)
				}
			})

		if (isProb3()) {
			const newNavs = navigation.filter(n => n.name === 'B3 BANDS' || n.name === 'CHECK OUT');
			setNavs(newNavs)
		}
	}, [])

	return (
		<Disclosure as="nav" className="absolute inset-x-0 top-0 z-50 border-b-[1px] border-[#e6e6e6] text-black">
			{({ open }) => (
				<>
					<div className="mx-auto max-w-9xl px-2 lg:px-6 lg:px-8 font-['Agrandir TextBold']">
						<div className="relative flex  xl:h-16 lg:h-[74px] h-16 items-center justify-between">
							<div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
								{/* Mobile menu button*/}
								<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
									)}
								</Disclosure.Button>
							</div>
							<div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
								<div className="hidden lg:ml-6 lg:block w-full">
									<div className="flex xl:space-x-4 space-x-1 justify-between">
										{navs.map((item) => (
											<NavLink key={item.path} to={item.path}>
												{({ isActive }) => (
													<Disclosure.Button
														className={clsx(
															isActive ? 'bg-[#00adef] text-white' : 'text-black text-lg font-bold hover:bg-[#00adef] hover:text-white',
															'rounded-md px-3 py-2 text-lg font-bold'
														)}
														aria-current={isActive ? 'page' : undefined}
													>
														{item.name}
													</Disclosure.Button>
												)}
											</NavLink>
										))}
									</div>
								</div>
							</div>
							<div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
								<NavLink to='/check-out'>
									<button
										type="button"
										className="hover:rounded-full hover:bg-gray-800 p-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
									>
										<span className="sr-only">View Carts</span>
										<ShoppingCartIcon fill='black' className="h-6 w-6" aria-hidden="true" />
									</button>
								</NavLink>

								<span>{name}</span>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="lg:hidden bg-white text-black">
						<div className="space-y-1 px-2 pt-2 pb-3 ">
							{navs.map((item) => (
								<NavLink key={item.path} to={item.path}>
									{({ active }) => (
										<Disclosure.Button
											className={clsx(
												active ? 'bg-gray-900 text-black' : 'text-black hover:bg-gray-700 hover:text-white',
												'block rounded-md px-3 py-2 text-base font-medium'
											)}
											aria-current={active ? 'page' : undefined}
										>
											{item.name}
										</Disclosure.Button>
									)}
								</NavLink>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}