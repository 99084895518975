import * as Yup from 'yup';
// import moment from 'moment';

const visaRegEx = /^(3\d{14}$)|([456]\d{15})$/;
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const memberWebsitePasswordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{0,24}$/;

const requiredFieldErrorMsg = 'Field is Required';

export const shippingFormSchema =
  Yup.object().shape({
    firstName: Yup.string().required(requiredFieldErrorMsg),
    lastName: Yup.string().required(requiredFieldErrorMsg),
    address: Yup.string().required(requiredFieldErrorMsg),
    city: Yup.string().required(requiredFieldErrorMsg),
    postalCode: Yup.string().required(requiredFieldErrorMsg).test(
      'len',
      `Length should be 5`,
      val => val && val.length === 5
    ),
    state: Yup.string().required(requiredFieldErrorMsg).test('len', 'Please select the state', val => val && val.length === 2),
    phone: Yup.string().required(requiredFieldErrorMsg).matches(phoneRegEx, 'Invalid format'),
    email: Yup.string().required(requiredFieldErrorMsg).matches(emailRegEx, 'Invalid format'),
    // memberWebsitePassword: Yup.string().required(requiredFieldErrorMsg).matches(memberWebsitePasswordRegEx, 'Must Include at least 1 capital letter, 1 small letter, 1 number and 1 special character'),
    showPassword: Yup.boolean(),
    memberWebsitePassword:
      Yup.string()
        .when("showPassword", {
          is: true,
          then: () => Yup.string().required(requiredFieldErrorMsg).matches(memberWebsitePasswordRegEx, 'Must Include at least 1 capital letter, 1 small letter, 1 number and 1 special character')
        }),
    ShipToFirstName: Yup.string().required(requiredFieldErrorMsg),
    ShipToLastName: Yup.string().required(requiredFieldErrorMsg),
    ShipToAddress: Yup.string().required(requiredFieldErrorMsg),
    ShipToCity: Yup.string().required(requiredFieldErrorMsg),
    ShipToPostalCode: Yup.string().required(requiredFieldErrorMsg).test(
      'len',
      `Length should be 5`,
      val => val && val.length === 5
    ),
    ShipToState: Yup.string().required(requiredFieldErrorMsg).test('len', 'Please select the state', val => val && val.length === 2),
    ShipToPhone: Yup.string().required(requiredFieldErrorMsg).matches(phoneRegEx, 'Invalid format'),
    ShipToEmail: Yup.string().required(requiredFieldErrorMsg).matches(emailRegEx, 'Invalid format'),
  })

export const cardFormSchema = Yup.object().shape({
  cardholder: Yup.string().required(requiredFieldErrorMsg),
  cardNumber: Yup.string()
    .required(requiredFieldErrorMsg)
    .matches(visaRegEx, 'Invalid card number'),
  expMonth: Yup.string().required(requiredFieldErrorMsg),
  expYear: Yup.string().required(requiredFieldErrorMsg),
  cvv: Yup.string().required(requiredFieldErrorMsg).test('len', 'Invalid CVV', val => val && (val.length === 3 || val.length === 4)),
});
